@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,300&display=swap');

.Intro{
    font-family: 'Poppins', sans-serif;
    background-color: #FBFBFB;
    height: 600px;
    line-height:600px;
    text-align: center;
}
.Introinside{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    
}
.Introinside h1{
    font-size: 60px;

}
.underline{
    border-bottom: 7px solid #C36A2D;
}
.About{
    font-family: 'Poppins', sans-serif;
    background-color: #EADCA6; 
    text-align: center;
}
.About h1{
    font-size: 60px;
    color: #C36A2D;
    font-weight: bold;

}
.About img{
    height: 300px;
    border-bottom:8px solid #C36A2D;
    border-Top:8px solid #C36A2D;

}
@media only screen and (max-width: 600px) {

    .Introinside h1{
        font-size: 30px;
    
    }
  }